import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateEventForm() {
    const [formData, setFormData] = useState({
        name: '',
        class: '',
        number: '',
        interest: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if ((formData.name === "" || formData.name === null) || (formData.class === "" || formData.class === null) || (formData.interest === "" || formData.interest === null) || (formData.number === "" || formData.number === null)) {
            toast("Invalid Please Fill Form Properly !!",);
        }
        else if (formData.number.length < 10) {
            toast("Please Number Check Properly",);

        }
        else if (formData.number.length > 10) {
            toast("Please Number Check Properly !!",);

        }
        else if (formData.interest.match("Choose") || formData.interest === "") {
            toast("Please Select Interest !!",);

        }
        else {
            if (formData.number.length === 10) {
                const formEle = document.querySelector("form");
                console.log(formEle)
                const formDatab = new FormData(formEle);
                await fetch(
                    "https://script.google.com/macros/s/AKfycbwhXZmOzp5qaJzSf56QiwnLZmhPU6hTsDk1lzi8quRw5FSNMsKJHAa7dzH3B24yR_I/exec",
                    {
                        method: "POST",
                        body: formDatab
                    }
                )
                    .then((res) => {res.json()
                        console.log(res)
                        toast("data sucesfully Submitted !!!")

                    })
                    .then((data)  =>  {
                        
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            else{
                toast("Invalid Please Fill Form Properly !!",);
            }

        }

    };
    const handleCancel = () => {
        setFormData({
            name: '',
            class: '',
            number: '',
            interest: ''
        })
    };

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <ToastContainer position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                    <div className="max-w-md mx-auto">
                        <div className="flex items-center space-x-5">
                            <div className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">i</div>
                            <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                <h2 className="leading-relaxed">Join Us Volunteer</h2>
                                <p className="text-sm text-gray-500 font-normal leading-relaxed">Join Us: Volunteer, Impact Lives, and Enrich Your Own Journey.</p>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <div className="flex flex-col">
                                    <label className="leading-loose">Volunteer Name</label>
                                    <input type="text" value={formData.name} name="name"
                                        onChange={handleChange} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Name" />
                                </div>
                                <div className="flex flex-col">
                                    <label className="leading-loose"> Class</label>
                                    <input type="text" value={formData.class} name='class'
                                        onChange={handleChange} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Optional" />
                                </div>
                                <div className="flex flex-col">
                                    <label className="leading-loose"> Whatsapp No</label>
                                    <input type="number" value={formData.number} name='number'
                                        onChange={handleChange} className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Optional" />
                                </div>
                                <div className="flex items-center space-x-4">
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Interest</label>
                                        <select id="interest" name="interest" value={formData.interest} onChange={handleChange}

                                            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600">
                                            <option defaultValue={true}>Choose a interest</option>
                                            <option value="Chess">Chess</option>
                                            <option value="Football">Football</option>
                                            <option value="Computer">Computer</option>
                                            <option value="Cooking">Cooking</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                </div>

                            </div>
                            <div className="pt-4 flex items-center space-x-4">
                                <button type="button" onClick={handleCancel} className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                    <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg> Cancel
                                </button>
                                <button type="submit" className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEventForm;
