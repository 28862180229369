import React from 'react'
import Navbar from '../components/Navbar'
import Card1 from '../components/Card1'

import "../styles/TutorStyle.css";
import img from '../assets/What is AI.png';
import img2 from "../assets/img2.png";
import img3 from '../assets/img3.png';

import Footer from '../components/Footer';

function Tutors() {


  let data = [
    {
      img:img,
      expertise: " Data Science, Python, LLM, AI, ML", qualification: "BSc in Statistics",
      experience: "1 year in the field", teaching_experience: "3 years, conducting over 50 workshops", awards: "Best Speaker at National Students Parliament, awarded by Balratna award.",
      social_media: " https://youtu.be/0ETp89OvRiE?si=psgBGzRYCJcjlfFG",website:null
    }, {
      img:img2,

      expertise: "Digital Marketing", qualification: "BE , Diploma in Digital Marketing ",
      experience: "6 year in the field", teaching_experience: "5years, conducting over 10 workshops", awards: "Best Speaker at National Students Parliament, awarded by Balratna award.",
      social_media: " https://youtu.be/0ETp89OvRiE?si=psgBGzRYCJcjlfFG", website:" https://www.pdmdigitalinstitute.com/"
    }, {
      img:img3,

      expertise: " Personality Development ", qualification: "BSc in Statistics",
      experience: "1 year in the field", teaching_experience: "3 years, conducting over 50 workshops", awards: "Best Speaker at National Students Parliament, awarded by Balratna award.",
      social_media: " https://youtu.be/0ETp89OvRiE?si=psgBGzRYCJcjlfFG",website:null
    }
  ]

  return (
    <>
      <Navbar />




      <div className='card-1 inline-block'>

        {
          data.map((i) => {
            return <Card1 img={i.img} expertise={i.expertise} qualification={i.qualification} experience={i.experience} social_media={i.social_media} awards={i.awards} teaching_experience={i.teaching_experience} />
          })

        }


      </div>

        <div className='my-10'>
          <Footer/>
        </div>
    </>
  )
}

export default Tutors
