import React, { useState } from 'react'
import '../../src/styles/NavbarStyles.css'
import { CgMenuRight } from "react-icons/cg";
import { RxCross1 } from "react-icons/rx";
import img from '../assets/Logo.png';
import { useNavigate } from 'react-router-dom';
function Navbar() {
  const [hamburger, setHamburger] = useState(true);
  const navigate = useNavigate();
  return (
    <navbar className='navbar_parent'>
      <div className='navbar_child_1  w-full'>
        <img src={img} className='logo' onClick={() => navigate("/")} ></img>
      </div>
      <div className='navbar_child_2 dont-show'>
        <ul className='unorder_list'>
          <li className="list_1 text-gray-900" onClick={() => navigate("/")}>Home</li>
          <li className="list_4" onClick={() => navigate("/workshop")}>Workshop</li>
          
          <li className="list_4"  onClick={()=>{
                  navigate("/");

                  setTimeout(() => {
                    document.querySelector(`#form_div`).scrollIntoView()
                  }, 500);
          }}>Registration</li>
          <li className="list_4" onClick={() => navigate("/tutors")}>Tutors</li>

          <li className="list_3" onClick={() => navigate("/contactUs")}>Contact Us</li>

        </ul>
      </div>
      <div className='navbar_child_3'>
        {/* <button className=" dont-show" id="button">Contact Us</button> */}
        {hamburger ? <CgMenuRight size={30} onClick={() => setHamburger(!hamburger)} className='button-1 dont-show-button show-button' id="button1" /> :
          <RxCross1 size={30} onClick={() => setHamburger(!hamburger)} className='button-2 dont-show-button show-button ' id="button2" />}
      </div>
      {!hamburger ?
        <div className='navbar_child_4 dont-show-button show-button' style={{ transition: 'all 200ms ease-in-out' }} >

          <ul className='unorder_list_1'>
            <li className="list_1" onClick={() => navigate("/")}>Home</li>
            <li className="list_4" onClick={() => navigate("/workshop")}>Workshop</li>
            <li className="list_4" onClick={() => {
              navigate("/");

              setTimeout(() => {
                document.querySelector(`#form_div`).scrollIntoView()
              }, 500);
            }} >Registration</li>
          <li className="list_4" onClick={() => navigate("/tutors")}>Tutors</li>

            <li><button id="button" onClick={() => navigate("/contactUs")}>Contact Us</button></li>
          </ul>
        </div> : <div className='navbar_child_5 dont-show-button show-button'>
        </div>

      }
    </navbar>
  )
}

export default Navbar
