import React from 'react';
import img from '../assets/What is AI.png';

const Card1 = (props) => {

  console.log(props)

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
        <a href="">
          <img className="w-full" src={props.img} alt="Sunset in the mountains" />
        </a>
        <div className="relative -mt-5 px-10 pt-5 pb-16 bg-white m-10">
          <p className="text-gray-500 text-xl my-1">
            Expertise : {props.expertise}
          </p>
          <p className="text-gray-500 text-xl my-1">
            Qualification : {props.qualification}
          </p>  <p className="text-gray-500 text-xl my-1">
            Experience :{props.experience}
          </p>
          <p className="text-gray-500 text-xl my-1">
            Teaching Experience : {props.teaching_experience}
          </p>
          {props.website == null ? <p className="text-gray-500 text-xl my-1 text-indigo-500">
          </p> : <a href={props.website}>click here2</a>
          }
          <p className="text-gray-500 text-xl my-1 text-indigo-500">
            <a href={props.social_media}>click here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card1;
