import React from 'react'
import Navbar from '../components/Navbar';

import "../styles/WorkshopStyles.css";

function Workshop() {
  let images2 = [
    {
      img: "https://ideogram.ai/api/images/direct/ifOHlvbVR6me1SmC59wDDw.png", text: "Finance Management"
    }
    , {
      img: "https://ideogram.ai/api/images/direct/TAXCfRUgTeeUY1UAmEOtXg.jpg", text: "What is AI ?"
    }, {
      img: "https://ideogram.ai/api/images/direct/YAFBdHuIRXS0RWqF73VsRg.png", text: "Insights into Architecture"
    }, {
      img: "https://ideogram.ai/api/images/direct/rxhepwmySt6QV0Hgo2ezdw.jpg", text: ""
    },
  ]
  let images3 = [
    {
      img: "https://ideogram.ai/api/images/direct/jEB7nFNmRjqZAFTTAjNL0w.png", text: "Mental Health Awareness "
    }
    , {
      img: "https://ideogram.ai/api/images/direct/CNZM5f4MTx-nNJM0o73jCQ.png", text: "Personality Development"
    }, {
      img: "https://ideogram.ai/api/images/direct/VqIBd_9HSheThTDKCLEcNg.png", text: "Python For Data Science"
    }, {
      img: "https://ideogram.ai/api/images/direct/rzXCQJ54T8u8rHDMyVzrkA.jpg", text: "DIY and Jewelry Making"
    },
  ]


  let images4 = [
    {
      img: "https://ideogram.ai/api/images/direct/Ik52IWd9R52Utux7VOPGSA.jpg", text: "Exploring Mandala Art"
    }
    , 
  ]
  return (
    <>
    <Navbar/>
    
    <div className="card">

      <h1> See All Workshjop</h1>
    <div  className="card-1">
        {
          images2.map((e) =>
            <div className="flex w-1/6 p-1 md:p-2 relative w-full text-center" >
              <img
                alt="gallery"
                className="block h-full w-full  rounded object-cover object-center"
                src={e.img} />
              <span className='md:text-3xl text-white' id="span">{e.text}</span>
              <div
                class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(240,0%,35%,0.3)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 rounded-lg"></div>
            </div>


          )
        }


      </div>
      <div className="card-2" >
        {
          images4.map((e) =>
            <div className="flex w-1/6 p-1 md:p-2 relative w-full text-center" >
              <img
                alt="gallery"
                className="block h-full w-full  rounded object-cover object-center"
                src={e.img} />
              <span className='md:text-3xl text-white' id="span">{e.text}</span>
              <div
                class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(240,0%,35%,0.3)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 rounded-lg"></div>
            </div>


          )
        }


      </div>

      <div className="card-3" >
        {
          images3.map((e) =>
            <div className="flex w-1/6 p-1 md:p-2 relative w-full text-center" >
              <img
                alt="gallery"
                className="block h-full w-full  rounded object-cover object-center"
                src={e.img} />
              <span className='md:text-3xl text-white' id="span">{e.text}</span>
              <div
                class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(240,0%,35%,0.3)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 rounded-lg"></div>
            </div>


          )
        }


      </div>
    </div>

    </>
    
  )
}

export default Workshop;
