import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import img from './assets/summercamp.png'
import Home from './pages/Home';
import WorkShop from './pages/Workshop';
import NotFound from './pages/NotFound';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CreateEventForm from './components/CreateEventForm';
import ContactUs from './pages/ContactUs';
import Tutors from './pages/Tutors';
function App() {
  return (
    
    <BrowserRouter>
    <Routes>
      <Route path='/' Component={Home}/>
      <Route path='/workshop' Component={WorkShop}/>
      <Route path='/tutors' Component={Tutors}/>

      <Route path='/joinus' Component={CreateEventForm}/>
      <Route path='/contactUs' Component={ContactUs}/>

      <Route path='/*' Component={NotFound}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
