import React from 'react';

import img from '../assets/What is AI.png';
import background from '../assets/testimonial.png';

import youtubelogo from '../assets/youtube.png';
import linkedin from '../assets/linkedin(1).png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
const Testimonials = () => {

  const navigate = useNavigate();
  return (
    <section className="text-center flex flex-col items-center justify-center my-10">
      <h2 className="mb-12 text-3xl font-bold">Our Experts</h2>

      <div className='md:w-1/2 w-full'>
        <Swiper
          navigation={{ enabled: true }}
          parallax={{
            enabled: true
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}






          
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide >
            <div className="mb-5 flex justify-center relative">
              <img
                src={img}
                className="w-3/4  shadow-lg dark:shadow-black/20"
              />
              <div className='p-4 absolute transform m-0 -bottom-10' >
                <img src={youtubelogo} alt="" className='w-16' />

              </div>




            </div> </SwiperSlide>
          <SwiperSlide>
            <div className="mb-5 flex justify-center relative">
              <img
                src={img}
                className="w-3/4  shadow-lg dark:shadow-black/20"
              />
              <div className='p-4 absolute transform m-0 -bottom-10' >
                <img src={youtubelogo} alt="" className='w-16' />

              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>  <div className="mb-5 flex justify-center relative">
            <img
              src={img}
              className="w-3/4  shadow-lg dark:shadow-black/20"
            />
            <div className='p-4 absolute transform m-0 -bottom-10' >
              <img src={youtubelogo} alt="" className='w-16' />

            </div>
          </div></SwiperSlide>
          <SwiperSlide>  <div className="mb-5 flex justify-center relative">
            <img
              src={background}
              className="w-3/4  shadow-lg dark:shadow-black/20"
            />

            <div className='p-4 absolute transform m-0 bottom-12' >
              <button className='bg-indigo-500 px-16 py-5 rounded-full text-white my-5 text-xl text-white-900 ' onClick={() => navigate("/tutors")} >Know Your Tutors
              </button>


            </div>
            <div className='p-12 absolute transform m-0 translate-y-3/4' >

              <span className='text-2xl'>Unveiling the Secrets Behind Effective Teaching</span>


            </div>

          </div></SwiperSlide>

        </Swiper>
      </div>



    </section>
  );
};

export default Testimonials;
