import React from 'react'
import { Link } from "react-router-dom";
import image_404 from "../assets/404-error.png"
import gify from '../assets/giphy.gif';
import "../styles/NotFound.css";
function NotFound() {
  return (
       <>
      <main className=" bg-white px-6 py-25 sm:py-20 lg:px-8 flex flex-col justify-center items-center border " style={{backgroundImage:`url(${gify})`,backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"cover",overflow:"hidden"}}>
        <div className="flex flex-col justify-center items-center text-center">
          <div className='flex justify-center'>
          <img src={image_404} alt="" srcSet="" className='max-w-72'/>
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-7xl text-white">Page not found</h1>
          <p className="mt-6 text-3xl leading-7 text-gray-600 text-white">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/"
              className="rounded-md bg-indigo-600 px-8 py-8 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link >              
          </div>
        </div>
        
      </main>
    </>
  )
}

export default NotFound
