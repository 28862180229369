import React from 'react'

import "../styles/HeaderStyles.css";
import star from "../assets/star.png";
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
function Header() {
    const navigate = useNavigate();

    return (
        <header className='w-screen ' id='header'>
            <div className='text-center relative z-10'>

                <h1 className='md:text-9xl text-6xl font-bold my-5 text-gray-900'>Know <br /> Your Hobby</h1>
                <div className='border my-4  w-40  bg-indigo-700 md:h-4 h-2 mx-auto'></div>
                <h2 className='md:text-3xl text-2xl my-5 text-gray-900'>Unleash Your Passion: Discover, Dive Deep, and Delight in Your Hobby. It’s not just a pastime; it’s your gateway to joy and self-discovery. Explore your love, know your hobby!</h2>
                <div className='w-full text-center h-full ' >
        <Swiper
        navigation={{enabled:true}}
        
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,

        }}
        modules={[Autoplay, Pagination, Navigation]}
        >
<SwiperSlide>
<div  id='header_area'>
<img src="https://ideogram.ai/api/images/direct/ifOHlvbVR6me1SmC59wDDw.png" className='w-full md:max-h-96 object-cover object-center '/>

</div>  
</SwiperSlide>
<SwiperSlide>
<div>
<img src="https://ideogram.ai/api/images/direct/rxhepwmySt6QV0Hgo2ezdw.jpg" className='w-full md:max-h-96 object-cover object-center' />
</div>  
</SwiperSlide>
<SwiperSlide>
<div>
<img src="https://ideogram.ai/api/images/direct/jEB7nFNmRjqZAFTTAjNL0w.png" className='w-full md:max-h-96 object-cover object-center' />
<button className='bg-indigo-500 px-16 py-5 rounded-full text-white my-5 text-xl text-white-900' onClick={()=>navigate("/workshop")} id="span">SEE ALL WORKSHOP</button>
</div></SwiperSlide>
        </Swiper>
      </div>
                <img src={star} alt="" srcset="" className='absolute top-2 right-0 -z-50 md:h-full h-full ' />
            </div>
        
        </header>
    )
}

export default Header
