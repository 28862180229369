import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Section1 from '../components/Section1'
import Footer from '../components/Footer'
import Testimonials from '../components/Testimonials'
import MidPage from './MidPage'
import Tables from '../components/Tables'

function Home() {
  return (
    <>
    <Navbar/>
    <Header/>
    <Tables/>
    <MidPage/>
    <Footer/>
    </>
  )
}

export default Home
