import React from 'react'

function Tables() {


    let arr = [
        {
            workshop_name: "Personality Development", date: "25th May", time: "(6pm to 7:30pm)"
        },
         {
            workshop_name: "Digital Marketing Essentials", date: "25th and 26th May", time: "(5pm to 6pm)"
        }, 
        {
            workshop_name: "Mental Health Awareness", date: "26th May", time: "(6:15pm to 7:30pm)"
        }, 
        {
            workshop_name: "Python For Data Science", date: "27th May", time: "(5pm to 6pm)"
        },
         {
            workshop_name: "Introduction to Finance Managemnet", date: "27th May", time: "(6pm to 7pm)"
        },
         {
            workshop_name: "Insight into Architecture", date: "28th May", time: "(5pm to 6pm)"
        },
         {
            workshop_name: "DIY and Jewellery Making", date: "29th May", time: "(5pm to 6pm)"
        }, {
            workshop_name: "What is AI ?", date: "29th & 30th May", time: "(7pm to 8pm)"
        },{
            workshop_name: "Exploring Mandala Art", date: "30th May", time: "(5pm to 6pm)"
        }
    ]

    return (


        <div class="w-full">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-white">
                            Workshop Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-white">
                            Date
                        </th>
                        <th scope="col" class="px-6 py-3 text-white">
                            Time
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {
                        arr.map((e)=>{
                            return    <tr class="bg-white  border-b ">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-black text-xl">
                               {e.workshop_name}
                            </th>
                            <td class="px-6 py-4 text-xl text-black">
                                {e.date}
                            </td>
                            <td class="px-6 py-4 text-xl text-black">
                                {e.time}
                            </td>
    
                        </tr>
                        })
                    }


                </tbody>
            </table>
        </div>

    )
}

export default Tables
