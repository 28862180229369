import React from 'react'
import Navbar from '../components/Navbar'
import { CiLocationOn } from "react-icons/ci";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineAlternateEmail } from "react-icons/md";
import "../styles/ContactUsStyle.css";
import Footer from '../components/Footer';
function ContactUs() {
    return (
        <>
            <Navbar />
            <div>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Contact Us</h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
            </div>
            <section className=' '>


                <div  id="forms">
                    <div className="bg-white ">
                        <div className="py-8 lg:py-16 px-4 ">

                            <form className="space-y-8">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-xl font-medium text-gray-900 ">Your email</label>
                                    <input type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-5   dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@flowbite.com" required />
                                </div>
                                <div>
                                    <label htmlFor="subject" className="block mb-2 text-xl font-medium text-gray-900 ">Subject</label>
                                    <input type="text" id="subject" className="block  w-full text-xl text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light p-5" placeholder="Let us know how we can help you" required />
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="message" className="block mb-2 text-xl font-medium text-gray-900 ">Your message</label>
                                    <textarea id="message" rows="6" className="block p-2.5 w-full text-xl text-gray-900 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 p-5 text-black" placeholder="Leave a comment..."></textarea>
                                </div>
                                <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-indigo-400">Send message</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='' id="second_div">
                  
                    <div className='' id='second_div1'>
                        
                    <div
                            className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10 my-2">
                            <div className="relative z-10 mx-auto max-w-md">
                            <span className="absolute top-10  h-20 w-20 rounded-full bg-indigo-500 transition-all duration-300 group-hover:scale-[10] -my-10 -z-50"></span>

                                <span className="grid h-20 w-20 place-items-center rounded-full bg-indigo-500 transition-all duration-300 group-hover:bg-indigo-400">
                                <FaPhone  size={35} color='white' className='z-10' />

                                </span>
                                <div
                                    className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                                    <p>
                                        <p className='text-xl'>Yash Raut</p>
                                    <a href='tel:917276376759' className='text-xl'>+91 7276376759</a>

                                    </p>
                                    <p>
                                    <p className='text-xl'>Mridul Krishna Sharma</p>

                                    <a href='tel:918770299762' className='text-xl'>+91 8770299762</a>

                                    </p>
                                </div>
                                <div
                                    class="space-y-6 pt-5 text-xl leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                                    <p>EduCraft, 13, Maske Layout, Santaji Society, Narendra Nagar, Nagpur, Maharashtra 440015, Nagpur 440015</p>
                                </div>
                           
                            </div>
                        </div>
                        <div
                            class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10 my-2">
                            <div class="relative z-10 mx-auto max-w-md">
                            <span className="absolute top-10  h-20 w-20 rounded-full bg-indigo-500 transition-all duration-300 group-hover:scale-[10] -my-10 -z-50"></span>

                                <span class="grid h-20 w-20 place-items-center rounded-full bg-indigo-500 transition-all duration-300 group-hover:bg-indigo-400">
                                <CiLocationOn size={35} color='white' />
                                </span>
                                <div
                                    class="space-y-6 pt-5 text-xl leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                                    <p>EduCraft, 13, Maske Layout, Santaji Society, Narendra Nagar, Nagpur, Maharashtra 440015, Nagpur 440015</p>
                                </div>
                        
                            </div>
                        </div>

                        <div
                            class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
                            <div class="relative z-10 mx-auto max-w-md">
                            <span className="absolute top-10  h-20 w-20 rounded-full bg-indigo-500 transition-all duration-300 group-hover:scale-[10] -my-10 -z-50"></span>

                                <span class="grid h-20 w-20 place-items-center rounded-full bg-indigo-500 transition-all duration-300 group-hover:bg-indigo-400">
                                  <MdOutlineAlternateEmail size={30} color='white'/>
                                </span>
                                <div
                                    class="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                                        <p className='text-xl -mx-4'>For More queries</p>
                                    <a className='text-xl -mx-4 underline' href='mailto:anaghaeducation.edu@gmail.com'>anaghaeducation.edu@gmail.com</a>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer/>
        </>

    )
}

export default ContactUs
