import React from 'react';
import { FiInstagram } from "react-icons/fi";
import { FaFacebook } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import "../styles/FooterStyles.css"; 
const Footer = () => {

  const navigate=useNavigate();
  return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6" id="footer-1">
      <div className="container mx-auto px-4" >
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl fonat-semibold text-blueGray-700">Let's keep in touch!</h4>
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
              Find us on any of these platforms ,
            </h5>
            <div className="mt-6 lg:mb-0 mb-6">
              <button className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-4" type="button" onClick={()=> {
                window.location.href="https://www.instagram.com/anaghaeducation/";
              }}>
                <FiInstagram size={30}/>
              </button>
              <button className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button" onClick={()=> {
                window.location.href="https://www.instagram.com/anaghaeducation/";
              }}>
              <FaFacebook size={30}/>           
                 </button>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-500 text-xl font-semibold mb-2">Useful Links</span>
                <ul className="list-unstyled">
                  <li>
                  <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl" href="https://www.instagram.com/anaghaeducation/">Instagram</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl" href="https://www.instagram.com/anaghaeducation/">Facebook</a>
                  </li>
                 
                
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-blueGray-500 text-xl font-semibold mb-2">Other Resources</span>
                <ul className="list-unstyled">
                <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl" onClick={()=>navigate("/")}>Home</a>
                  </li>
                <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl" onClick={()=>navigate("/workshop")}>Workshop</a>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl" onClick={()=>{
                       navigate("/");

                       setTimeout(() => {
                         document.querySelector(`#form_div`).scrollIntoView()
                       }, 500);
                    }}>Registration</a>
                  </li>
                 
                
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xl"  onClick={()=>navigate("/contactUs")}>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-xl text-blueGray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2023-24</span><a className="text-blueGray-500 hover:text-gray-800" target="_blank"/> Anaghaeducation
              <a  className="text-blueGray-500 hover:text-blueGray-800">.com</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
