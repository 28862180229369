import React from 'react'
import img from "../assets/Blue Modern Dance Channel Youtube Banner.png";

function Section2() {
  return (
    <div className="w-full " id="form_div">
     <img src={img} alt=""  className=''/>
    </div>
  )
}

export default Section2
