import React from 'react'
import Section1 from '../components/Section1'
import Testimonials from '../components/Testimonials'
import Section2 from '../components/Section2'

function MidPage() {
  return (
    <div>
      {/* <Section1/> */}
      <Section2/>
      <Testimonials/>
    </div>
  )
}

export default MidPage
